import {useCallback, useEffect, useMemo, useState} from "react";
import ApiClient from "../../client/ApiClient";
import {DateRange} from "../../domain/DateRange";

const useCategories = (dateRange: DateRange, contentId: string|null, author: string|null) => {
    const [categories, setCategories] = useState<string[]>([]);

    const parameters = useMemo(() => {
        const params = {
            interval: dateRange.label
        }

        if (dateRange.start) {
            Object.assign(params, {start: dateRange.start.getTime()})
        }

        if (dateRange.end) {
            Object.assign(params, {end: dateRange.end.getTime()})
        }

        if (contentId) {
            Object.assign(params, {contentId})
        }

        if (author) {
            Object.assign(params, {author})
        }

        return params;
    },[dateRange, contentId, author])

    const getCategories = useCallback(async () => {
        const client = new ApiClient();

        try {
            const response = await client.request("get", "/categories", parameters)

            setCategories(response.data)
        } catch (exception) {
            setCategories([]);
        }
    }, [setCategories, parameters])

    useEffect(() => {
        getCategories().then();
    }, [getCategories])

    return {
        categories
    }
}

export default useCategories;