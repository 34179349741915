import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Summary} from "../domain/Summary";
import ApiClient from "../client/ApiClient";
import {ChartData} from "../domain/ChartData";
import {BarListData} from "../components/FilterBar/FiltersBarList";
import {Content as DomainContent, ContentTypologies, TypologyContent} from "../domain/Content";
import {AppContext} from "../pages/Home";
import {FiltersList} from "../domain/Filters";
import {DateRange, DateRangeOptions} from "../domain/DateRange";
import useAuthors from "./filters/use-authors";
import useCategories from "./filters/use-categories";
import {ContentsContextInterface} from "./contexts/use-contents-context";
import {Sorting} from "../domain/Sorting";

export interface AnalyticsData {
    summary: Summary|null
    chart: ChartData[]
    stats: StatsData,
    contents: DomainContent[]|TypologyContent[]|null
}

export interface StatsData {
    traffic: BarListData[]|null
    source: BarListData[]|null
    device: BarListData[]|null
    contentType: BarListData[]|null
    platform: BarListData[]|null
    user: BarListData[]|null
    country: BarListData[]|null
    temporality: BarListData[]|null
    language: BarListData[]|null
    topics: BarListData[]|null
    campaign: BarListData[]|null
}

interface GetDataParameters {
    limit: number
    interval: DateRangeOptions
    start?: number
    end?: number
    account?: number
    contentType?: string
    traffic?: string
    device?: string
    source?: string
    author?: string
    category?: string
    topic?: string,
    sorting?: Sorting
}

const useAnalyticsData = (
    filters: FiltersList,
    dateRange: DateRange,
    limit: number,
    typology: ContentTypologies|null,
    contentId: string|null,
    category: string|null,
    author: string|null,
    topic: string|null
): ContentsContextInterface => {
    const {selectedAccount} = useContext(AppContext)
    const [analyticsData, setAnalyticsData] = useState<AnalyticsData>(initialData)
    const client = useMemo(() => new ApiClient(), [])
    const {authors} = useAuthors(dateRange, contentId, category);
    const {categories} = useCategories(dateRange, contentId, author);
    const [sort, setSort] = useState<Sorting|null>(null);
    const [accumulatedSourceFilters, setAccumulatedSourceFilters] = useState<string[]>([]);
    const [accumulatedContentTypeFilters, setAccumulatedContentTypeFilters] = useState<string[]>([]);
    const [accumulatedCountryFilters, setAccumulatedCountryFilters] = useState<string[]>([]);
    const [accumulatedLangFilters, setAccumulatedLangFilters] = useState<string[]>([]);
    const [accumulatedTopicFilters, setAccumulatedTopicFilters] = useState<string[]>([]);
    const [accumulatedCampaignFilters, setAccumulatedCampaignFilters] = useState<string[]>([]);

    const parameters: GetDataParameters = useMemo(() => {
        const params = {
            limit,
            interval: dateRange.label
        }

        if (dateRange.start) {
            Object.assign(params, {start: dateRange.start.getTime()})
        }

        if (dateRange.end) {
            Object.assign(params, {end: dateRange.end.getTime()})
        }

        if (selectedAccount) {
            Object.assign(params, {account: selectedAccount.id})
        }

        if (filters.contentType.length > 0) {
            Object.assign(params, {contentType: filters.contentType.join(",")})
        }

        if (filters.traffic.length > 0) {
            Object.assign(params, {traffic: filters.traffic.join(",")})
        }

        if (filters.device.length > 0) {
            Object.assign(params, {device: filters.device.join(",")})
        }

        if (filters.source.length > 0) {
            Object.assign(params, {source: filters.source.join(",")})
        }

        if (filters.platform.length > 0) {
            Object.assign(params, {platform: filters.platform.join(",")})
        }

        if (filters.temporality.length > 0) {
            Object.assign(params, {temporality: filters.temporality.join(",")})
        }

        if (filters.country.length > 0) {
            Object.assign(params, {country: filters.country.join(",")})
        }

        if (filters.user.length > 0) {
            Object.assign(params, {user: filters.user.join(",")})
        }

        if (filters.language.length > 0) {
            Object.assign(params, {language: filters.language.join(",")})
        }

        if (filters.author.length > 0) {
            Object.assign(params, {author: filters.author.join(",")})
        }

        if (filters.category.length > 0) {
            Object.assign(params, {category: filters.category.join(",")})
        }

        if (filters.topics.length > 0) {
            Object.assign(params, {topics: filters.topics.join(",")})
        }

        if (filters.campaign.length > 0) {
            Object.assign(params, {campaign: filters.campaign.join(",")})
        }

        if (typology) {
            Object.assign(params, {typology})
        }

        if (contentId) {
            Object.assign(params, {contentId})
        }

        if (category) {
            Object.assign(params, {category})
        }

        if (author) {
            Object.assign(params, {author})
        }

        if (topic) {
            Object.assign(params, {topics: topic})
        }

        if (sort) {
            Object.assign(params, {sorting: sort.field})
            Object.assign(params, {order: sort.order})
        }

        return params
    }, [selectedAccount, filters, dateRange, limit, typology, contentId, category, author, topic, sort])

    useEffect(() => {
        setAnalyticsData(initialData)
    }, [setAnalyticsData, selectedAccount])

    // Accumulate source stats
    useEffect(() => {
        setAccumulatedSourceFilters((current) => {
            const filters = analyticsData.stats.source?.map((s) => s.name) ?? []

            for (const source of current) {
                if (!filters.some((filter) => filter === source)) {
                    filters.push(source);
                }
            }

            return filters;
        });
    }, [setAccumulatedSourceFilters, analyticsData.stats.source])

    // Accumulate content type stats
    useEffect(() => {
        setAccumulatedContentTypeFilters((current) => {
            const filters = analyticsData.stats.contentType?.map((s) => s.name) ?? []

            for (const contentType of current) {
                if (!filters.some((filter) => filter === contentType)) {
                    filters.push(contentType);
                }
            }

            return filters;
        });
    }, [setAccumulatedContentTypeFilters, analyticsData.stats.contentType])

    // Accumulate country stats
    useEffect(() => {
        setAccumulatedCountryFilters((current) => {
            const filters = analyticsData.stats.country?.map((s) => s.name) ?? []

            for (const country of current) {
                if (!filters.some((filter) => filter === country)) {
                    filters.push(country);
                }
            }

            return filters;
        });
    }, [setAccumulatedCountryFilters, analyticsData.stats.country])

    // Accumulate language stats
    useEffect(() => {
        setAccumulatedLangFilters((current) => {
            const filters = analyticsData.stats.language?.map((s) => s.name) ?? []

            for (const language of current) {
                if (!filters.some((filter) => filter === language)) {
                    filters.push(language);
                }
            }

            return filters;
        });
    }, [setAccumulatedLangFilters, analyticsData.stats.language])

    // Accumulate topic stats
    useEffect(() => {
        setAccumulatedTopicFilters((current) => {
            const filters = analyticsData.stats.topics?.map((s) => s.name) ?? []

            for (const topic of current) {
                if (!filters.some((filter) => filter === topic)) {
                    filters.push(topic);
                }
            }

            return filters;
        });
    }, [setAccumulatedTopicFilters, analyticsData.stats.topics])

    // Accumulate campaign stats
    useEffect(() => {
        setAccumulatedCampaignFilters((current) => {
            const filters = analyticsData.stats.campaign?.map((s) => s.name) ?? []

            for (const campaign of current) {
                if (!filters.some((filter) => filter === campaign)) {
                    filters.push(campaign);
                }
            }

            return filters;
        });
    }, [setAccumulatedCampaignFilters, analyticsData.stats.campaign])

    const resolveResetData = useCallback((forceStats = false) => {
        const data = {chart: [], summary: null}

        if (!contentId && !category && !author) {
            Object.assign(data, {contents: null})
        }

        if (forceStats) {
            Object.assign(data, {stats: initialData.stats})
        }

        setAnalyticsData((current) => ({...current, ...data}))
    }, [setAnalyticsData, contentId, category, author])

    // Show loading when filters change
    useEffect(() => {
        resolveResetData();
    }, [filters, resolveResetData])

    // Show loading when date filters change
    useEffect(() => {
        resolveResetData(true);
    }, [dateRange, resolveResetData])

    // Show loading only for content when change typology or sorting criteria
    useEffect(() => {
        setAnalyticsData((current) => ({...current, contents: null}))
    }, [typology, sort])

    // Reset data to show loading when change content/dashboard view
    useEffect(() => {
        setAnalyticsData(initialData)
        setAccumulatedSourceFilters([]);
        setAccumulatedContentTypeFilters([]);
        setAccumulatedCountryFilters([]);
        setAccumulatedLangFilters([]);
        setAccumulatedTopicFilters([]);
        setAccumulatedCampaignFilters([])
    }, [contentId, category, author, topic])

    useEffect(() => {
        let timeoutId: string | number | NodeJS.Timeout | undefined;
        const fetchData = () => {
            client.abort()
            client.request("get", "/data", parameters).then((response) => {
                const data: AnalyticsData = response.data;

                setAnalyticsData(data)

                if (dateRange.label === 'realtime 5 minutes' ||
                    dateRange.label === 'realtime 30 minutes' ||
                    dateRange.label === 'today') {

                    timeoutId = setTimeout(fetchData, 10000);
                }
            }).catch((error) => {console.error(error)})
        }

        fetchData();

        return () => {
            clearTimeout(timeoutId);
        };
    }, [client, parameters, dateRange.label])

    const selectContent = useCallback((content: DomainContent|TypologyContent) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setAnalyticsData((current) => ({...current, contents: current.contents?.filter((c: DomainContent|TypologyContent) => {
            if ('url' in content && 'url' in c) {
                return c.uuid === content.uuid
            }

            return c.title === content.title;
        }) ?? null}))
    }, [setAnalyticsData])

    // Merge current source stats with accumulated
    const accumulatedSourceStats = useMemo(() => {
        if (!analyticsData.stats.source) {
            return null;
        }

        const source = analyticsData.stats.source;

        for (const accumulated of accumulatedSourceFilters) {
            if (!source.some((s) => s.name === accumulated)) {
                source.push({name: accumulated, value: 0})
            }
        }

        return source;
    }, [analyticsData.stats.source, accumulatedSourceFilters]);

    // Merge current content type stats with accumulated
    const accumulatedContentTypeStats = useMemo(() => {
        if (!analyticsData.stats.contentType) {
            return null;
        }

        const contentType = analyticsData.stats.contentType;

        for (const accumulated of accumulatedContentTypeFilters) {
            if (!contentType.some((s) => s.name === accumulated)) {
                contentType.push({name: accumulated, value: 0})
            }
        }

        return contentType;
    }, [analyticsData.stats.contentType, accumulatedContentTypeFilters]);

    // Merge current country stats with accumulated
    const accumulatedCountryStats = useMemo(() => {
        if (!analyticsData.stats.country) {
            return null;
        }

        const country = analyticsData.stats.country;

        for (const accumulated of accumulatedCountryFilters) {
            if (!country.some((s) => s.name === accumulated)) {
                country.push({name: accumulated, value: 0})
            }
        }

        return country;
    }, [analyticsData.stats.country, accumulatedCountryFilters]);

    // Merge current language stats with accumulated
    const accumulatedLanguageStats = useMemo(() => {
        if (!analyticsData.stats.language) {
            return null;
        }

        const language = analyticsData.stats.language;

        for (const accumulated of accumulatedLangFilters) {
            if (!language.some((s) => s.name === accumulated)) {
                language.push({name: accumulated, value: 0})
            }
        }

        return language;
    }, [analyticsData.stats.language, accumulatedLangFilters]);

    // Merge current language stats with accumulated
    const accumulatedTopicStats = useMemo(() => {
        if (!analyticsData.stats.topics) {
            return null;
        }

        const topic = analyticsData.stats.topics;

        for (const accumulated of accumulatedTopicFilters) {
            if (!topic.some((s) => s.name === accumulated)) {
                topic.push({name: accumulated, value: 0})
            }
        }

        return topic;
    }, [analyticsData.stats.topics, accumulatedTopicFilters]);

    // Merge current campaign stats with accumulated
    const accumulatedCampaignStats = useMemo(() => {
        if (!analyticsData.stats.campaign) {
            return null;
        }

        const campaign = analyticsData.stats.campaign;

        for (const accumulated of accumulatedCampaignFilters) {
            if (!campaign.some((s) => s.name === accumulated)) {
                campaign.push({name: accumulated, value: 0})
            }
        }

        return campaign;
    }, [analyticsData.stats.campaign, accumulatedCampaignFilters]);

    const filtersList = useMemo(() => {
        const allFilters = {
            traffic: analyticsData.stats.traffic?.map((data) => data.name) ?? [],
            source: accumulatedSourceStats?.map((data) => data.name) ?? [],
            device: analyticsData.stats.device?.map((data) => data.name) ?? [],
            contentType: accumulatedContentTypeStats?.map((data) => data.name) ?? [],
            platform: analyticsData.stats.platform?.map((data) => data.name) ?? [],
            user: analyticsData.stats.user?.map((data) => data.name) ?? [],
            country: accumulatedCountryStats?.map((data) => data.name) ?? [],
            temporality: analyticsData.stats.temporality?.map((data) => data.name) ?? [],
            language: accumulatedLanguageStats?.map((data) => data.name) ?? [],
            topics: accumulatedTopicStats?.map((data) => data.name) ?? [],
            campaign: accumulatedCampaignStats?.map((data) => data.name) ?? [],
            author: authors,
            category: categories,
        }

        Object.entries(allFilters).forEach(([key, value]) => {
            if (Array.isArray(value) && value.length === 0) {
                delete allFilters[key as keyof typeof filtersList];
            }
        });

        return allFilters;
    }, [
        analyticsData.stats,
        authors, categories,
        accumulatedSourceStats,
        accumulatedContentTypeStats,
        accumulatedCountryStats,
        accumulatedLanguageStats,
        accumulatedTopicStats,
        accumulatedCampaignStats
    ])

    return {
        analyticsData,
        selectContent,
        filters: filtersList,
        sorting: sort,
        setSorting: setSort,
        accumulatedSourceStats,
        accumulatedContentTypeStats,
        accumulatedCountryStats,
        accumulatedLanguageStats,
        accumulatedTopicStats,
        accumulatedCampaignStats
    }
}

const initialData: AnalyticsData = {
    summary: null,
    chart: [],
    stats: {
        traffic: null,
        source: null,
        device: null,
        contentType: null,
        platform: null,
        user: null,
        country: null,
        temporality: null,
        language: null,
        topics: null,
        campaign: null,
    },
    contents: null
}

export default useAnalyticsData