import React from "react";
import useAnalyticsData from "../hooks/use-analytics-data";
import DashboardView from "./DashboardView";
import ContentView from "./ContentView";
import {useParams} from "react-router-dom";
import {ContentsContext} from "../hooks/contexts/use-contents-context";
import {FiltersContext, useFiltersContextData} from "../hooks/contexts/use-filters-context";
import TypologyView from "./TypologyView";

const Dashboard = () => {
    const {contentId: contId, category, author, topic} = useParams();
    const contentId = contId ? contId : null;

    const filtersContextData = useFiltersContextData()

    const analyticsHook = useAnalyticsData(
        filtersContextData.filters,
        filtersContextData.dateRange,
        filtersContextData.limit,
        filtersContextData.typology,
        contentId,
        category ?? null,
        author ?? null,
        topic ?? null
    )

    return (
        <FiltersContext.Provider value={filtersContextData}>
            <ContentsContext.Provider value={analyticsHook}>
                {(!contentId && !category && !author && !topic) && <DashboardView />}
                {contentId && <ContentView />}
                {(category || author || topic) && <TypologyView />}
            </ContentsContext.Provider>
        </FiltersContext.Provider>
    )
}

export default Dashboard