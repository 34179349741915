import React, {useEffect, useState} from 'react';
import Suggestion from "./Suggestion";
import useContents from "../../hooks/use-contents";
import SearchSkeleton from "../Common/Loading/SearchSkeleton";
import {useTranslation} from "react-i18next";

interface SearchSuggestionsProps {
    searchValue: string;
    onClose: () => void;
}

const SEARCH_QUANTITY_ITEMS = 10;

function SearchSuggestions({ searchValue, onClose }: SearchSuggestionsProps) {
    const {search, contents} = useContents();
    const [delay, setDelay] = useState<NodeJS.Timeout>();
    const [isLoading, setLoading] = useState<boolean>(false);
    const {t} = useTranslation()

    useEffect(() => {
        setLoading(true);
        clearTimeout(delay);

        const t = setTimeout(() => {
            if (searchValue.length > 0) {
                search(searchValue, SEARCH_QUANTITY_ITEMS).then(() => setLoading(false));
            }
        }, 1000)

        setDelay(t);
    }, [search, searchValue])

    return (
        <>
            {searchValue.length > 0 && (
                <div className="cc-header__search-suggest">
                    { isLoading &&
                        <SearchSkeleton quantity={SEARCH_QUANTITY_ITEMS} />
                    }
                    { (!isLoading && contents.length === 0) &&
                        <div className='cc-header__search-suggest-item cc-header__search-suggest-item--no-results'>{t('no results')}</div>
                    }
                    { (!isLoading && contents.length > 0) && contents?.map((content) => {
                        return <Suggestion content={content} key={content.uuid}/>
                    }) }
                </div>
            )}
        </>
    );
}

export default SearchSuggestions;
